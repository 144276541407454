import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useFormik } from 'formik';
import { FormEventHandler, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '@mui/material';

import { userPool } from '../../helpers';

const Signup = ({ setUser }) => {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSignup = (values) => {
    const email = values.email.trim();
    const password = values.password.trim();
    const attributeList = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
    ];
    userPool.signUp(email, password, attributeList, [], (err, result) => {
      if (err) {
        setError(err.message);
        return;
      }
      setUser(userPool.getCurrentUser());
      const from = location.state?.from || '/';
      navigate(from);
    });
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      verifyPassword: '',
    },
    validationSchema: yup.object({
      email: yup.string().email('Invalid email address').required('Required'),
      password: yup
        .string()
        .required('No password provided')
        .matches(/[0-9]/, 'Password requires at least 1 number')
        .matches(/[a-z]/, 'Password requires at least 1 lowercase letter')
        .matches(/[A-Z]/, 'Password requires at least 1 uppercase letter')
        .matches(/[^\w]/, 'Password requires at least 1 special character')
        .min(8, 'Password should be at least 8 characters long'),
      verifyPassword: yup
        .string()
        .oneOf([yup.ref('password'), ''], 'Passwords must match'),
    }),
    onSubmit: (values) => {
      handleSignup(values);
    },
  });

  return (
    <>
      <div className="form" onSubmit={formik.handleSubmit as FormEventHandler}>
        <h2>Sign Up</h2>
        <label htmlFor="email">Email</label>
        <input
          name="email"
          type="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="error">{formik.errors.email}</div>
        ) : null}
        <label htmlFor="password">Password</label>
        <input
          name="password"
          type="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
        />
        {formik.touched.password && formik.errors.password ? (
          <div className="error">{formik.errors.password}</div>
        ) : null}
        <label htmlFor="verifyPassword">Verify Password</label>
        <input
          name="verifyPassword"
          type="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.verifyPassword}
        />
        {formik.touched.verifyPassword && formik.errors.verifyPassword ? (
          <div className="error">{formik.errors.verifyPassword}</div>
        ) : null}
        {error && <div className="error">{error}</div>}
        <Button
          className="primary-button"
          variant="contained"
          onClick={() => handleSignup(formik.values)}>
          Sign Up
        </Button>
        <Button
          className="secondary-button"
          variant="text"
          onClick={() => handleLogin()}>
          Log In
        </Button>
      </div>
    </>
  );
};

export default Signup;
