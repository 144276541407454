import { useRef, useState } from 'react';

import { PutObjectCommand, PutObjectInput } from '@aws-sdk/client-s3-browser';
import { LoadingButton } from '@mui/lab';
import { Button, FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material';

import Toast, { IToast } from '../../components/Toast';
import { s3Client, s3Folder, uploadBucketName } from '../../helpers';
import Files from './Files';

const columns = [
  {
    id: 'progress',
    label: '',
  },
  {
    id: 'uuid',
    label: 'uuid',
  },
  {
    id: 'status',
    label: 'status',
  },
  {
    id: 'createdAt',
    label: 'created at',
  },
];

export default function Process() {
  const pollInterval = 10000;

  const fileRef = useRef<HTMLInputElement>(null);

  const [error, setError] = useState('');
  const [toastMessage, setToastMessage] = useState<IToast>({
    message: 'Test message',
    show: false,
    status: 'fail',
  });
  const [offset, setOffset] = useState(0);
  const [file, setFile] = useState(null);
  const [parser, setParser] = useState('ejrice');
  const [fileProgress, setFileProgress] = useState({});
  const [processing, setProcessing] = useState(false);

  // TODO: Implement functionality for campaigns
  // const [campaigns, setCampaigns] = useState();
  // const getCampaigns = async (offset) => {
  //   const session = await Auth.currentSession();

  //   const response = await fetch(
  //     `https://gcvgnpmfse.execute-api.us-east-1.amazonaws.com/prod/campaigns?offset=${offset}`,
  //     {
  //       method: "GET",
  //       mode: "cors",
  //       headers: {
  //         Authorization: session.getIdToken().getJwtToken(),
  //       },
  //     }
  //   );
  //   const data = await response.json();
  //   setCampaigns(data);
  // };

  // useEffect(() => {
  //   (async () => {
  //     getCampaigns(offset);
  //   })();
  // }, [offset]);

  const onFileChange = (setState, files) => {
    setError('');
    setState(Array.from(files));
  };

  const onParserChange = (event) => {
    setParser(event.target.value);
  };

  const updateToast = (message, show, status) => {
    setToastMessage({
      message,
      show,
      status,
    });
    const timer = setTimeout(() => {
      setToastMessage({ message: '', show: false, status: 'fail' });
      clearTimeout(timer);
    }, 5000);
  };

  const putFile = async (file: File) => {
    const path = `${s3Folder}/${parser}/${file.name}`;
    const params: PutObjectInput = {
      Bucket: uploadBucketName,
      Key: path,
      Body: file,
      ContentType: 'text/csv',
    };
    const uploadCommand = new PutObjectCommand(params);
    try {
      await s3Client.send(uploadCommand);
      setError('');
      updateToast('File successfully uploaded!', true, 'success');
    } catch (err: any) {
      setError('Sorry, there was an issue uploading your file!');
    }
  };

  // TODO: Implement functionality for campaigns
  // useEffect(() => {
  //   const pollStatus = async (campaign) => {
  //     if (campaign.status === "processing") {
  //       const session = await Auth.currentSession();
  //       const timer = async () => {
  //         const response = await fetch(
  //           `https://gcvgnpmfse.execute-api.us-east-1.amazonaws.com/prod/campaign/status?campaignUuid=${campaign.uuid}`,
  //           {
  //             method: "GET",
  //             mode: "cors",
  //             headers: {
  //               Authorization: session.getIdToken().getJwtToken(),
  //             },
  //           }
  //         );
  //         const data = await response.json();
  //         if (campaign.status !== data.status) {
  //           const index = campaigns.findIndex(
  //             (needle) => needle.uuid === campaign.uuid
  //           );
  //           setCampaigns([
  //             ...campaigns.slice(0, index),
  //             {
  //               ...campaign,
  //               status: data.status,
  //               message: data.message,
  //             },
  //             ...campaigns.slice(index + 1),
  //           ]);
  //         }
  //         if (data.status !== "processing") {
  //           clear(interval);
  //         }
  //       };
  //       const interval = setInterval(timer, pollInterval);
  //       const clear = (interval) => {
  //         clearInterval(interval);
  //       };
  //     }
  //   };
  //   if (campaigns) {
  //     campaigns.forEach((campaign) => pollStatus(campaign));
  //   }
  // }, [campaigns]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      !fileRef ||
      !fileRef.current ||
      !fileRef.current.files ||
      fileRef.current.files.length === 0
    ) {
      setError('Please select a file to upload!');
      return;
    }

    setProcessing(true);

    const results = await putFile(fileRef.current.files[0]);

    // TODO: Implement functionality for calling apigateway
    // const session = await Auth.currentSession();
    // let jwtToken = '';
    // await userPool
    //   .getCurrentUser()
    //   ?.getSession((error, session: CognitoUserSession) => {
    //     if (error) {
    //       console.error(error);
    //       return;
    //     }
    //     jwtToken = session.getIdToken().getJwtToken();
    //   });
    // WebsiteApi gateway
    // const response = await fetch(
    //   // 'https://gcvgnpmfse.execute-api.us-east-1.amazonaws.com/prod/process',
    //   'https://ewfpxyljj8.execute-api.us-east-1.amazonaws.com/prod/process',
    //   {
    //     method: 'POST',
    //     mode: 'cors',
    //     headers: {
    //       Authorization: jwtToken,
    //     },
    //     body: JSON.stringify({
    //       // bucket: awsconfig.aws_user_files_s3_bucket,
    //       bucket:
    //         'govtmailstack-s3uploadbucketnesteds-bucket83908e77-zfglbhvfult1',
    //       file: fileRef.current.files[0].name,
    //     }),
    //   },
    // );
    // const data = await response.json();
    // console.log('data:', data);
    // setCampaigns([data, ...campaigns.slice(0, campaigns.length - 1)]);

    setFile(null);
    fileRef.current.value = '';
    setProcessing(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h2>File to Process</h2>
        <FormControl
          size="small"
          variant="outlined"
          sx={{ m: 1, minWidth: 150 }}
          required>
          <InputLabel>Select Parser</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            value={parser}
            onChange={onParserChange}
            label="Select Parser *">
            <MenuItem value="ejrice">EJ Rice</MenuItem>
            <MenuItem value="ulrich">Ulrich</MenuItem>
            <MenuItem value="vanguard">Vanguard</MenuItem>
          </Select>
        </FormControl>
        <Paper style={{ padding: '1rem', margin: '1rem 0' }}>
          <label htmlFor="file">Property file</label>{' '}
          <Button onClick={() => fileRef?.current?.click()}>Select</Button>
          <input
            type="file"
            id="file"
            name="file"
            ref={fileRef}
            onChange={(e) => onFileChange(setFile, e.target.files)}
            style={{ display: 'none' }}
          />
          <Files files={file} fileProgress={fileProgress} />
        </Paper>
        {error && <div className="error">{error}</div>}
        {/* TODO: Implement components after associated functionality */}
        {/* <Files files={propertyFile} /> */}
        {/* <Paper style={{padding: '1rem', marginBottom: '1rem'}}>
          <label htmlFor='mailingFiles'>Mailing file(s)</label> <Button onClick={() => mailingFilesRef.current.click()}>Select</Button>
          <input type='file' id='mailingFiles' name='mailingFiles' ref={mailingFilesRef} onChange={(e) => onFileChange(setMailingFiles, e.target.files)} style={{display: 'none'}} multiple />
          <Files files={mailingFiles} fileProgress={fileProgress} />
        </Paper> */}
        {/* <Files files={mailingFiles} /> */}
        <LoadingButton
          className="loading-button"
          type="submit"
          variant="contained"
          loading={processing}>
          submit
        </LoadingButton>
      </form>
      {toastMessage.show && (
        <Toast
          message={toastMessage.message}
          show={toastMessage.show}
          status={toastMessage.status}
        />
      )}
      {/* TODO: Implement components after associated functionality */}
      {/* {campaigns && (
        <Paper style={{ padding: "1rem", margin: "1rem 0" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {campaigns.map((campaign) => {
                  return <FileRow columns={columns} campaign={campaign} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )} */}
    </div>
  );
}
