import { ErrorMessage, Field, Form, Formik, useField } from 'formik';
import { useEffect, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@mui/material';

import { apiGatewayEndpoint } from '../../helpers';

type ValuesType = {
  email: string;
  phone: string;
  optOut: boolean;
};

const PhoneInputField = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props.name);

  return (
    <PhoneInput
      {...props}
      {...field}
      value={field.value}
      country="US"
      onChange={(value) => {
        if (value) helpers.setValue(value);
      }}
    />
  );
};

export default function Optin() {
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();
  const [initialValues, setInitialValues] = useState<ValuesType>({
    email: '',
    phone: '',
    optOut: false,
  });

  const event: string = searchParams.get('event') || '';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url: string = `${apiGatewayEndpoint}/communications/${event}`;
        const response = await fetch(url);
        const responseData = await response.json();

        if (responseData.status === 'success' && responseData.taxpayer) {
          setInitialValues({
            email: responseData.taxpayer.email,
            phone: '+1' + responseData.taxpayer.number,
            optOut: false,
          });
          setError('');
        } else {
          setError(
            'Could not find your taxpayer information! Contact us for help!',
          );
        }
      } catch (error) {
        setError(
          'Could not find your taxpayer information! Contact us for help!',
        );
      }
    };

    fetchData();
  }, [event, setInitialValues]);

  const validate = (values) => {
    const errors = {};
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
      errors['email'] = 'Invalid email address';
    if (!isValidPhoneNumber(values.phone, 'US'))
      errors['phone'] = 'Invalid phone number';
    setError('');
    return errors;
  };

  const onSubmit = (values, { setSubmitting }) => {
    if (values.messageTypes.length === 0) {
      setError('No method of notification selected!');
      return;
    } else setError('');
    // Send opt-in options to api
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize={true}>
      {({ values, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
        <Form className="form" onSubmit={handleSubmit}>
          <h2>Opt-In</h2>
          <label htmlFor="email">Email</label>
          <Field name="email" type="email" />
          <ErrorMessage className="error" name="email" component="div" />
          <label htmlFor="phone">Phone Number</label>
          <PhoneInputField
            name="phone"
            type="tel"
            label="phone"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
            country="US"
          />
          <ErrorMessage className="error" name="phone" component="div" />
          {error && <div className="error">{error}</div>}
          <div role="group">
            <label className="checkbox">
              <Field
                className="checkbox"
                type="checkbox"
                name="optout"
                value="optout"
              />
              Opt out of receiving future notifications
            </label>
          </div>
          <Button
            type="submit"
            className="primary-button"
            variant="contained"
            disabled={isSubmitting}>
            Confirm
          </Button>
        </Form>
      )}
    </Formik>
  );
}
