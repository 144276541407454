import { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

const PolicyContent = ({ file }) => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await fetch(`/${file}.html`);
        const htmlText = await response.text();
        setHtmlContent(htmlText);
      } catch (error) {
        setHtmlContent('');
      }
    };

    fetchHtmlContent();
  }, [file]);

  return htmlContent.length > 0 ? (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  ) : (
    <div className="error">Error: Cannot load policy page!</div>
  );
};

const OverlayComponent = ({ open, handleClose, policyFile }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <PolicyContent file={policyFile} />
      </DialogContent>
    </Dialog>
  );
};

export default OverlayComponent;
