import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { useFormik } from 'formik';
import { FormEventHandler, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '@mui/material';

import { userPool } from '../../helpers';

const Login = ({ setUser }) => {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = (values) => {
    const cognitoUser = new CognitoUser({
      Username: values.email,
      Pool: userPool,
    });

    const authenticationDetails = new AuthenticationDetails({
      Username: values.email,
      Password: values.password,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        setUser(userPool.getCurrentUser());
        const from = location.state?.from || '/';
        navigate(from);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        setError('User needs to set new password');
      },
      onFailure: (err) => {
        setError(err.message);
      },
    });
  };

  const handleSignUp = () => {
    navigate('/signup');
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup.string().email('Invalid email address').required('Required'),
      password: yup.string().required('No password provided.'),
    }),
    onSubmit: (values) => {
      handleLogin(values);
    },
  });

  return (
    <>
      <div className="form" onSubmit={formik.handleSubmit as FormEventHandler}>
        <h2>Sign In</h2>
        <label htmlFor="email">Email</label>
        <input
          name="email"
          type="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="error">{formik.errors.email}</div>
        ) : null}
        <label htmlFor="password">Password</label>
        <input
          name="password"
          type="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
        />
        {formik.touched.password && formik.errors.password ? (
          <div className="error">{formik.errors.password}</div>
        ) : null}
        {error && <div className="error">{error}</div>}
        <Button
          className="primary-button"
          variant="contained"
          onClick={() => handleLogin(formik.values)}>
          Log In
        </Button>
        <Button
          className="secondary-button"
          variant="text"
          onClick={() => handleSignUp()}>
          Sign Up
        </Button>
      </div>
    </>
  );
};

export default Login;
