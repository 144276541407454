import { Box } from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import {
  TimelineEmailCreatedListItem,
  TimelineEmailValidationListItem,
  TimelineListItem,
} from './components';

export default function Timeline() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [taxpayer, setTaxpayer] = useState();

  // TODO: Implement taxpayer retrieval
  // useEffect(() => {
  //   (async () => {
  //     const session = await Auth.currentSession();

  //     const response = await fetch(
  //       `https://gcvgnpmfse.execute-api.us-east-1.amazonaws.com/prod/taxpayer/email-summary?assessorSlug=${searchParams.get(
  //         'assessor',
  //       )}&taxpayerNumber=${searchParams.get('taxpayer-number')}`,
  //       {
  //         method: 'GET',
  //         mode: 'cors',
  //         headers: {
  //           Authorization: session.getIdToken().getJwtToken(),
  //         },
  //       },
  //     );
  //     const data = await response.json();
  //     console.log(data);
  //     setTaxpayer(data);
  //   })();
  // }, [searchParams]);

  const getEventComponent = (event) => {
    switch (event.eventType) {
      case 'email-validation':
        return TimelineEmailValidationListItem;
      case 'email-created':
        return TimelineEmailCreatedListItem;
      default:
        return TimelineListItem;
    }
  };

  return (
    <Box>
      {/* TODO: Implemnet taxpayer retrieval */}
      {/* {taxpayer &&
        <>
        <Box sx={{padding: '1rem'}}>
          <Typography variant="h5" component="h1">{taxpayer.firstName} {taxpayer.lastName}</Typography>
          <Box>{taxpayer.taxpayerNumber}</Box>
        </Box>
        <Box sx={{padding: '1rem'}}>
          <TimelineList>
            {taxpayer.events.map((event) => {
              const ListItem = getEventComponent(event);
              return (
                <ListItem event={event} />
              )
            })}
          </TimelineList>
        </Box>
        </>
      } */}
    </Box>
  );
}
