import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
} from '@mui/material';

export default function Files(props) {
  const getSize = (size) => {
    const fSExt = ['Bytes', 'KB', 'MB', 'GB'];
    let i = 0;
    while (size > 900) {
      size /= 1024;
      i++;
    }
    return `${Math.round(size * 100) / 100} ${fSExt[i]}`;
  };

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {props.files &&
            props.files.map((file) => (
              <TableRow key={file.name}>
                <TableCell>
                  {file.name}
                  {props.fileProgress && props.fileProgress[file.name] && (
                    <LinearProgress
                      variant="determinate"
                      value={
                        (props.fileProgress[file.name].loaded /
                          props.fileProgress[file.name].total) *
                        100
                      }
                    />
                  )}
                </TableCell>
                <TableCell align="right">{getSize(file.size)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
