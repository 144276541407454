import './App.css';
import './styles/base.scss';

import { useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { Box, Button, Container, Grid, Link } from '@mui/material';

import Overlay from './components/Overlay';
import { userPool } from './helpers';
import Login from './routes/authentication/Login';
import Signup from './routes/authentication/Signup';
import Process from './routes/file/Process';
import ProtectedRoute from './routes/ProtectedRoute';
import Optin from './routes/taxpayer/Optin';
import Timeline from './routes/taxpayer/Timeline';

const NavbarLink = (props) => (
  <Link
    sx={{
      color: '#000',
      cursor: 'pointer',
      textDecorationColor: '#000',
      paddingLeft: '1rem',
    }}
    onClick={props.onClick}>
    {props.children}
  </Link>
);

type PolicyFileType = 'privacy' | 'terms';

export default function App() {
  const [user, setUser] = useState(userPool.getCurrentUser());
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [policyFile, setPolicyFile] = useState<PolicyFileType>('privacy');

  const signOut = () => {
    user?.signOut(() => {
      setUser(null);
    });
  };

  const handleOverlay = (file: PolicyFileType) => {
    setOverlayOpen(!overlayOpen);
    setPolicyFile(file);
  };

  return (
    <Router>
      <Container>
        <Box
          style={{
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>
          <Box>
            {user && (
              <Grid
                container
                spacing={2}
                sx={{ marginTop: 0, paddingBottom: '1rem' }}>
                <Grid xs={6}>
                  <Box
                    component="img"
                    sx={{ width: '10rem' }}
                    src="/images/ejr-logo.png"
                  />
                  <Box>Test County (logo here)</Box>
                </Grid>
                <Grid xs={6}>
                  <Box style={{ textAlign: 'right' }}>
                    <Button variant="text" onClick={signOut}>
                      Sign out
                    </Button>
                    <NavbarLink onClick={() => handleOverlay('privacy')}>
                      Privacy Policy
                    </NavbarLink>
                    <NavbarLink onClick={() => handleOverlay('terms')}>
                      Terms of Service
                    </NavbarLink>
                  </Box>
                </Grid>
              </Grid>
            )}
            <nav>
              <Routes>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute user={user}>
                      <Process />
                    </ProtectedRoute>
                  }
                />
                <Route path="/login" element={<Login setUser={setUser} />} />
                <Route path="/opt-in" element={<Optin />} />
                <Route path="/signup" element={<Signup setUser={setUser} />} />
                <Route
                  path="/taxpayer"
                  element={
                    <ProtectedRoute user={user}>
                      <Timeline />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </nav>
          </Box>
          <Box sx={{ textAlign: 'center' }}>Powered by EJ Rice</Box>
          <Overlay
            open={overlayOpen}
            handleClose={handleOverlay}
            policyFile={policyFile}
          />
        </Box>
      </Container>
    </Router>
  );
}
