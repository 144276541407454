import '../styles/toast.scss';

import { useEffect, useState } from 'react';

export interface IToast {
  message: string;
  show: boolean;
  status: 'success' | 'fail';
}

const Toast = ({ message, show, status }: IToast) => {
  const [visible, setVisible] = useState(show);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [show]);

  return visible ? <div className={`toast ${status}`}>{message}</div> : null;
};

export default Toast;
